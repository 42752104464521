<template>
  <div class="card">
    <h2 class="card-title">Listado de Procedimientos</h2>
    <div v-if="procedimientos">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="procedimientos"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="verProcedimiento"
      ></table-list>
    </div>
  </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
import { ProcedimientosServices } from "../services/ProcedimientosServices";

export default {
  name: "ListarProcedimientosAdmin",
  components: { TableList },
  data() {
    return {
      procedimientos: null,
      headers: ProcedimientosServices.headers(),
      body: ProcedimientosServices.body(),
      actionButton: {
        text: "Nuevo procedimiento",
        action: () => {
          this.$router.push({ name: "CrearProcedimiento" });
        },
      },
    };
  },
  mounted() {
    ProcedimientosServices.api
      .getProcedimientos()
      .then((response) => (this.procedimientos = response.procedimientos));
  },
  methods: {
    verProcedimiento(procedimiento) {
      this.$router.push({ name: "VerProcedimiento", params: { id: procedimiento.id } });
    },
  },
};
</script>
